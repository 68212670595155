<template>
  <v-layout wrap>
    <v-divider></v-divider>
    <b>معلومات أخر زيارة</b>
    <v-divider></v-divider>
    <v-layout wrap>
      <v-flex class="mb-3" xs6 sm6 md6>
        <b>اسم المهمة</b>
      </v-flex>
      <v-flex class="mb-3" xs6 sm6 md6>
        <strong>{{ info.task.name }}</strong>
      </v-flex>
      <v-flex class="mb-3" xs6 sm6 md6>
        <b>الحالة</b>
      </v-flex>
      <v-flex class="mb-3" xs6 sm6 md6>
        <strong>
          {{
            info.task.statusId == 4
              ? "جديدة"
              : info.task.statusId == 3
              ? "منجزة"
              : info.task.statusId == 5
              ? "بانتظار المراجعة"
              : info.task.statusId == 2
              ? "قيد التقدم"
              : "-"
          }}</strong
        >
      </v-flex>

      <v-flex class="mb-3" xs6 sm6 md6>
        <b>المستخدم المنشئ</b>
      </v-flex>
      <v-flex class="mb-3" xs6 sm6 md6>
        <strong>{{ userCreator }}</strong>
      </v-flex>

      <v-flex class="mb-3" xs6 sm6 md6>
        <b>المستخدم الموكلة إليه</b>
      </v-flex>

      <v-flex class="mb-3" xs6 sm6 md6>
        <strong>{{ userResponsible }}</strong>
      </v-flex>

      <v-flex class="mb-3" xs6 sm6 md6>
        <b>تاريخ البداية</b>
      </v-flex>
      <v-flex class="mb-3" xs6 sm6 md6>
        <strong>{{ createdDate(info.task.startDate) }}</strong>
      </v-flex>

      <v-flex class="mb-3" xs6 sm6 md6>
        <b>تاريخ النهاية</b>
      </v-flex>
      <v-flex class="mb-3" xs6 sm6 md6>
        <strong>{{ createdDate(info.task.endDate) }}</strong>
      </v-flex>

      <v-flex class="mb-3" xs6 sm6 md6>
        <b>نوع التكرار</b>
      </v-flex>
      <v-flex class="mb-3" xs6 sm6 md6>
        <strong>{{
          info.task.reapetType == "d"
            ? "يومي"
            : info.task.reapetType == "w"
            ? "أسبوعي"
            : info.task.reapetType == "m"
            ? "شهري"
            : info.task.reapetType == "y"
            ? "سنوي"
            : "-"
        }}</strong>
      </v-flex>

      <v-flex class="mb-3" xs6 sm6 md6>
        <b>عدد مرات التكرار</b>
      </v-flex>
      <v-flex class="mb-3" xs6 sm6 md6>
        <strong>{{ info.task.reapets }}</strong>
      </v-flex>

      <v-flex class="mb-3" xs6 sm6 md6>
        <b>الملاحظات</b>
      </v-flex>
      <v-flex class="mb-3" xs6 sm6 md6>
        <strong>{{ info.notes ? info.notes : "-" }}</strong>
      </v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
export default {
  props: ["info"],
  data() {
    return {
      transporterName: "",
      userCreator: "",
      userResponsible: "",
      users: []
    };
  },
  methods: {
    async getUsersName() {
      this.users = [];
      await this.ApiService.get("auth/listusers")
        .then(res => {
          this.users = res.data;
          this.users.forEach(el => {
            if (el.user.id == this.info.task.creatorId) {
              this.userCreator = el.user.fullName;
            }
            if (el.user.id == this.info.task.responsibleId) {
              this.userResponsible = el.user.fullName;
            }
          });
        })
        .catch(() => {});
    },
    createdDate(date) {
      const current_datetime = new Date(date);
      return current_datetime.toLocaleDateString("en-US");
    },
    getTransporter(id) {
      this.ApiService.get("Transporters/getalltransporters")
        .then(res => {
          var allTransporters = res.data.responseData;
          allTransporters.forEach(el => {
            if (el.idtransporter == id) {
              this.transporterName = el.name;
            }
          });
        })
        .catch(() => {});
    }
  },
  created() {
    this.user = JSON.parse(window.localStorage.getItem("authUser"));
    this.role = window.localStorage.getItem("roles");
    this.getUsersName();
    this.getTransporter(this.info.transporterId);
  }
};
</script>
<style scoped>
b,
strong {
  font-size: 14px;
}
</style>
