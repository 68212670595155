<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex>
        <v-card elevation="4" width="800" class="mx-auto">
          <v-card-title primary-title>
            <h3 class="headline mb-0">
              {{ external != null ? external.name : project.name }}
            </h3>
          </v-card-title>
          <v-divider></v-divider>
          <v-flex class="mb-5" xs12 sm12 md12 v-if="showMap">
            <gmap-map
              :center="center"
              :zoom="8"
              style="width:100%;  height: 300px;"
            >
              <gmap-marker :position="marker"></gmap-marker>
            </gmap-map>
          </v-flex>
          <v-card-text v-if="showExternal">
            <v-layout wrap>
              <v-flex class="mb-3" xs6 sm6 md6>
                <b>المالك</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <strong>{{ external.owner }}</strong>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <b>الموقع</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <strong>{{ external.location }}</strong>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <b>الموبايل</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <strong>{{ external.mobile }}</strong>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <b>الإيميل</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <strong>{{ external.email }}</strong>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <b>نوع النشاط</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <strong>{{ external.activity.name }}</strong>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6 v-if="showLastVisit">
                <b>أخر زيارة</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6 v-if="showLastVisit">
                <strong>{{ createdDate(external.lastVisit) }}</strong>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <b>نوع الجهة الخارجية</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <strong>{{
                  external.type == "factory"
                    ? "مصنع"
                    : external.type == "contractor"
                    ? "ناقلين"
                    : external.type == "parking"
                    ? "موقف"
                    : "مشروع"
                }}</strong>
              </v-flex>
              <!--      <v-flex class="mb-3" xs6 sm6 md6>
                                <b>نوع الإسمنت المستخدم</b> 
                            </v-flex>
                            <v-flex class="mb-3" xs6 sm6 md6>
                                <strong>{{ external.cementType }}</strong>
                            </v-flex> -->
              <lastVisit v-if="showLastVisit" :info="lastVisit[0]" />
            </v-layout>
          </v-card-text>
          <!--------------------- Project Card-------------------------->
          <v-card-text v-else-if="showProject">
            <v-layout wrap>
              <v-flex class="mb-3" xs6 sm6 md6>
                <b>المالك</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <strong>{{ project.owner }}</strong>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <b>الموقع</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <strong>{{ project.location }}</strong>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <b>الموبايل</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <strong>{{ project.mobile }}</strong>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <b>الإيميل</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <strong>{{ project.email }}</strong>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <b>طبيعة النشاط</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <strong>{{ project.activityType }}</strong>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6 v-if="showLastVisit">
                <b>أخر زيارة</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6 v-if="showLastVisit">
                <strong>{{ createdDate(project.lastVisit) }}</strong>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <b>المساحة</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <strong>{{ project.space }}</strong>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <b>التكلفة</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <strong>{{ project.cost }}</strong>
              </v-flex>
              <!--     <v-flex class="mb-3" xs6 sm6 md6>
                                <b>نوع الإسمنت المستخدم</b> 
                            </v-flex>
                            <v-flex class="mb-3" xs6 sm6 md6>
                                <strong>{{ project.cementType }}</strong>
                            </v-flex>-->
              <v-flex class="mb-3" xs6 sm6 md6>
                <b>المقاول الرئيسي</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <strong>{{ mainContractor }}</strong>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <b>المقاول الثانوي</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <strong>{{ secondaryContractor }}</strong>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <b>المكتب الاستشاري</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <strong>{{ project.consultationOffice }}</strong>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <b>المكتب الهندسي</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <strong>{{ project.engineeringOofice }}</strong>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <b>حالة المشروع</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <strong>{{ project.projectStatus }}</strong>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <b>معلومات إضافية</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <strong>{{ project.details }}</strong>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <b>الناقل</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <strong>{{ transporterName }}</strong>
              </v-flex>
              <lastVisit v-if="showLastVisit" :info="lastVisit[0]" />
            </v-layout>
          </v-card-text>
          <!--------------------- Transporter Card-------------------------->
          <v-card-text v-else>
            <v-layout wrap>
              <v-flex class="mb-3" xs6 sm6 md6>
                <b>المالك</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <strong>{{ external.owner }}</strong>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <b>عدد السيارات</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <strong>{{ external.cars }}</strong>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <b>الموبايل</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <strong>{{ external.mobile }}</strong>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6 v-if="showLastVisit">
                <b>أخر زيارة</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6 v-if="showLastVisit">
                <strong>{{ createdDate(external.lastVisit) }}</strong>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <b>نوع الجهة الخارجية</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <strong>ناقل</strong>
              </v-flex>
              <lastVisit v-if="showLastVisit" :info="lastVisit[0]" />
            </v-layout>
          </v-card-text>
          <!--------------------- Photos -------------------------->
          <v-flex x12 md12 v-if="isPhotos">
            <v-row>
              <v-col
                v-for="photo in photos"
                :key="photo.idphoto"
                class="d-flex child-flex"
                cols="4"
              >
                <v-img
                  :src="path + photo.path"
                  aspect-ratio="1"
                  class="grey lighten-2"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
            </v-row>
          </v-flex>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="showLastVisit"
              style="background:#58dd5b;color:white;margin-left:5px;"
              @click="
                $router.push({
                  name: 'external-visits',
                  params: {
                    external: showExternal ? external : project,
                    externalId: external.idexternal
                  }
                })
              "
            >
              <b>سجل الزيارات</b>
            </v-btn>
            <!--    <v-btn
                            v-if="role=='Admin'"
                            style="background:#5867dd;color:white;margin-left:5px;"
                            @click="edit">
                            <b>تعديل الجهة الخارجية</b>
                        </v-btn>-->
            <v-btn @click="$router.go(-1)" style="background:gray;" dark>
              <b>رجوع</b>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import lastVisit from "./lastVisit.vue";
export default {
  components: {
    lastVisit
  },
  data() {
    return {
      isPhotos: false,
      photos: null,
      path: "",
      center: { lat: 24.4, lng: 46.71667 },
      marker: {
        lat: "",
        lng: "",
        label: ""
      },
      mainContractor: "",
      secondaryContractor: "",
      external: {
        name: "",
        owner: "",
        location: "",
        mobile: "",
        email: "",
        activityType: "",
        type: "",
        cementType: "",
        lastVisit: ""
      },
      users: [],
      showExternal: false,
      showProject: false,
      project: {
        name: "",
        owner: "",
        location: "",
        mobile: "",
        email: "",
        activityType: "",
        lastVisit: "",
        space: "",
        cost: "",
        cementType: "",
        consultationOffice: "",
        engineeringOofice: "",
        projectStatus: "",
        details: ""
      },
      showLastVisit: false,
      transporterName: ""
    };
  },
  mounted() {
    this.geolocate();
  },
  methods: {
    setPlace(place) {
      this.currentPlace = place;
    },
    geolocate: function() {
      navigator.geolocation.getCurrentPosition(() => {
        this.center = {
          lat: 24.4,
          lng: 46.71667
        };
      });
    },
    getContractorsName() {
      this.ApiService.get("external/getallexternals?id=3")
        .then(res => {
          let contractors = res.data.responseData;
          contractors.forEach(el => {
            if (el.idexternal == this.project.mainContractor) {
              this.mainContractor = el.name;
            }
            if (el.idexternal == this.project.secondaryContractor) {
              this.secondaryContractor = el.name;
            }
          });
        })
        .catch(() => {});
    },
    getTransporter(id) {
      this.ApiService.get("Transporters/getalltransporters")
        .then(res => {
          var allTransporters = res.data.responseData;
          allTransporters.forEach(el => {
            if (el.idtransporter == id) this.transporterName = el.name;
          });
        })
        .catch(() => {});
    },
    checkVisits() {
      let url = "";
      if (this.showExternal) {
        url =
          "visit/visitsByExternal?external=" +
          this.external.idexternal +
          "&isPagination=false";
      } else if (this.showProject) {
        url =
          "visit/visitsByExternal?external=" +
          this.project.idproject +
          "&isPagination=false";
      } else {
        url =
          "Visit/VisitsByTransporter?transporter=" +
          this.external.idtransporter +
          "&isPagination=false";
      }
      this.ApiService.get(url)
        .then(res => {
          if (res.data.responseData.length > 0) {
            this.lastVisit = res.data.responseData.reverse().splice(0, 1);
            this.showLastVisit = true;
          }
        })
        .catch(() => {});
    },
    createdDate(date) {
      const current_datetime = new Date(date);
      return current_datetime.toLocaleDateString("en-US");
    },
    edit() {
      if (this.showExternal) {
        this.$router.push({
          name: "edit-external",
          params: { external: this.external }
        });
      } else {
        this.$router.push({
          name: "edit-project",
          params: { project: this.peoject }
        });
      }
    },
    async getExternal() {
      let url = null;
      if (this.$route.name == "show-external") {
        this.showExternal = true;
        this.showProject = false;
        url = "External/getexternal?id=" + this.$route.params.externalId;
      } else if (this.$route.name == "show-project") {
        this.showExternal = false;
        this.showProject = true;
        url = "external/getproject?id=" + this.$route.params.externalId;
      } else {
        this.showExternal = false;
        this.showProject = false;
        this.showExternal = false;
        url = "Transporters/gettransporter?id=" + this.$route.params.externalId;
      }
      await this.ApiService.get(url)
        .then(res => {
          this.path = "https://salestools.yamamacement.com/"; //res.config.baseURL
          if (this.showProject) {
            this.project = res.data.responseData[0];
            if (this.project.photos != null || this.project.photos.length > 0) {
              this.isPhotos = true;
              this.photos = this.project.photos;
            }
            this.photos = this.project.photos;
            this.marker.lat = this.project.lat;
            this.marker.lng = this.project.lng;
            this.center.lat = this.project.lat;
            this.center.lng = this.project.lng;
            this.center.label = this.project.location;
            this.getTransporter(this.project.transporterId);
            this.getContractorsName();
          } else {
            if (this.$route.name == "show-external") {
              this.external = res.data.responseData[0];
            } else {
              this.external = res.data.responseData[0];
            }
            if (
              this.external.photos != null ||
              this.external.photos.length > 0
            ) {
              this.isPhotos = true;
              this.photos = this.external.photos;
            }
            this.marker.lat = this.external.lat;
            this.marker.lng = this.external.lng;
            this.center.lat = this.external.lat;
            this.center.lng = this.external.lng;
            this.center.label = this.external.location;
          }
        })
        .catch(() => {});
      this.checkVisits();
    }
  },
  computed: {
    showMap() {
      if (this.external.lat != null)
        return this.external.lat != null && this.external.lng != null;
      return this.project.lat != null && this.project.lng != null;
    }
  },
  created() {
    this.user = JSON.parse(window.localStorage.getItem("authUser"));
    this.role = window.localStorage.getItem("roles");
    this.getExternal();
  }
};
</script>
<style lang="scss">
b,
strong {
  font-size: 14px;
}
</style>
